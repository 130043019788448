<template>
    <!-- 订单列表页面 -->
    <div class="padding24 color_white" id="order_list">
      <a-spin :spinning="spinning" size="large" :tip="tip">
        <div v-show="$route.meta.isShow">
          <a-form layout="inline">
            <a-form-item label="订单编号">
              <a-input v-model="filter.orderNo" placeholder="请输入订单编号" @pressEnter="searchClick" />
            </a-form-item>
            <a-form-item label="用户账号">
              <a-input v-model="filter.userNo" placeholder="请输入用户账号" @pressEnter="searchClick"/>
            </a-form-item>
            <a-form-item>
              <a-button icon="search" type="primary" @click="searchClick" :disabled="tableLoading">
                搜索
              </a-button>
            </a-form-item>
          </a-form>
          <!-- 表格 -->
          <a-table
            :columns="mallOrderColumns"
            :data-source="tableData"
            :pagination="false"
            :loading="tableLoading"
            :scroll="{ x: 2600 }"
            :row-key="record => record.id"
          >
            <span slot="goodsType" slot-scope="goodsType">
              <span>{{ +goodsType === 0 ?  '实物商品': '虚拟商品' }}</span>
            </span>
            <span slot="payType" slot-scope="payType, record">
              <span>{{ payTypeMap.get(record.payType)}}</span>
            </span>
            <span slot="price" slot-scope="price, record">
              <span v-if=" record.goodsPrice">¥{{ record.goodsPrice }}</span>
              <span v-if="record.goodsPrice && record.creditPrice">/</span>
              <span v-if="record.creditPrice">{{ `${record.creditPrice}积分` }}</span>
            </span>
            <span slot="realPrice" slot-scope="realPrice, record">
              <span v-if="record.payAmount">¥{{ record.payAmount }}</span>
              <span v-else>{{ record.payPoints }}积分</span>
            </span>
            <div slot="receiptAddress" slot-scope="receiptAddress, record">
              <p>{{ record.receiptName }} {{ record.receiptPhone }}</p>
              <p>{{ record.receiptArea }} {{ receiptAddress }}</p>
            </div>
            <span slot="state" slot-scope="state, record">
              {{ getOrderStateMap.get(record.state)}}
            </span>
            <span slot="shippingStatus" slot-scope="shippingStatus, record">
              {{ getShippingStatusMap.get(record.shippingStatus)}}
            </span>
            <span slot="actions" slot-scope="actions, record">
              <a v-if="+record.state === 2 || +record.state === 7" class="margin_right20" @click="refundClick(record)">
                申请退款
              </a>
              <a v-if="+record.state === ORDER_STATUS_APPLY_REFUND && operation.confirmRefund" class="margin_right20" @click="confirmRefundFn(record)">
                确认退款
              </a>
              <a v-if="+record.state === ORDER_STATUS_APPLY_REFUND && operation.refundRefund" class="margin_right20" @click="rejectClick(record)">
                驳回申请退款
              </a>
              <a-dropdown v-if="+record.goodsType === 0"  placement="bottomCenter">
                <a class="ant-dropdown-link margin_right20" @click="(e) => e.preventDefault()">
                  更多
                  <a-icon type="down"/>
                </a>
                <a-menu slot="overlay">
                  <a-menu-item v-if="operation.showReceiptAddress">
                    <a class="margin_right20" @click="showReceiptAddressDlg(record)">收货地址</a>
                  </a-menu-item>
                  <a-menu-item >
                    <a class="margin_right20" @click="expressClick(record)">快递信息</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </span>
          </a-table>
          <!-- 分页功能 -->
          <MyPagination v-if="tableData.length > 0" :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" />
          <!-- 地址 -->
          <EditAddressModal :record="currentRecord" :modalVisible="addressModalVisible" @cancel="addressModalVisible = false" @ok="updateOrderReceiptAddress" />
          <!-- 快递单号的弹框 -->
          <EditMedal :expressVal="currentRecord.trackingNumber" :trackingName="currentRecord.trackingName" is-obj :modalVisible="modalVisible" @cancel="modalVisible = false" @ok="updateExpress"/>
          <!-- 订单导入失败的弹框 -->
          <ErrImport
            :modalVisible="errImportVisible"
            :errImportUrl="importUrl"
            :failCount="failCount"
            @cancel="() => (errImportVisible = false)"
            @modalCancel="() => (errImportVisible = false)"
            @errOrderFn="errOrderFn"
            @spinningFn="spinningFn"
          />
        </div>
      </a-spin>
      <router-view/>
    </div>
  </template>
  <script>
  import {
    getShopOrderAPI,
    shopOrderDownloadAPI,
    updateExpressAPI,
    updateReceiptAPI,
    shopOrderRefundAPI,
    orderDownAPI
  } from '@/request/api/orderformManage'
  import moment from 'moment'
  import MyPagination from '@/components/pagination/MyPagination'
  import ExpressNoImport from '@/components/file/ExpressNoImport.vue'
  import ErrImport from '../modules/ErrImport.vue'
  import EditAddressModal from '../modules/EditAddressModal'
  import EditMedal from '../modules/EditMedal'
  import ThreeNewsModal from '../modules/ThreeNewsModal.vue'
  import { Modal } from 'ant-design-vue'
  import { codeFn } from '@/utils/tools'
  import {
    mallOrderColumns,
    payTypeMap,
    getOrderStateMap,
    getShippingStatusMap,
    goodsTypeList,
    ORDER_STATUS_REFUND_FAILED,
    ORDER_STATUS_REFUNDING,
    ORDER_STATUS_APPLY_REFUND
  } from '../constants'
  
  export default {
    components: {
      MyPagination,
      EditAddressModal,
      EditMedal,
      ExpressNoImport,
      ErrImport,
      ThreeNewsModal,
    },
    mounted() {
      for (const key in this.operation) {
        this.operation[key] = !!codeFn(`MallOrder/${key}`)
      }
    },
    data() {
      return {
        ORDER_STATUS_REFUND_FAILED,
        ORDER_STATUS_REFUNDING,
        ORDER_STATUS_APPLY_REFUND,
        operation: {
          importOrders: false, 
          exportOrders: false,
          refundApply: false,
          editExpress: false,
          editAddress: false,
          downloadTemplate: false,
          refundRefund: false, // 驳回退款是否隐藏
          confirmRefund: false, // 确认退款是否隐藏
          showReceiptAddress: false
        },
        payTypeMap,
        getOrderStateMap,
        getShippingStatusMap,
        mallOrderColumns,
        rangePicker: [], // 下单时间的数组
        spinning: false, // 是否为全局加载中的状态
        tip: '',
        importUrl: '', // 订单导入失败的url
        failCount: null, // 订单导入失败的数据
        errImportVisible: false, // 订单导入失败的弹框是否显示
        currentRecord: {}, // 订单列表的当前项
        addressModalVisible: false, // 修改收货人地址信息弹窗是否显示
        modalVisible: false, // 快递模态框是否显示
        tableLoading: false, // 表格是否是加载中
        count: 0, // 列表数据的总条数
        pageNo: 1,
        pageSize: 20,
        inpVal: '', // 快递信息弹框的输入值
        tableData: [],
        filter: {
          orderNo: '',
          userNo: '',
          goodsName: '',
          goodsType: -1,
          state: 0,
          payType: 0,
          orderTimeStart: '',
          orderTimeEnd: '',
          shippingStatus: 0
        },
        goodsTypeList,
        isDisDownTemplate: false,
        isDisAllOrder: false,
        orderStateList: [],
        payTypeList: [],
        params: {},
        shippingStatusList: []
      }
    },
    created() {
      this.orderStateList = this.getMapToArr(this.getOrderStateMap)
      this.payTypeList = this.getMapToArr(this.payTypeMap)
      this.shippingStatusList = this.getMapToArr(this.getShippingStatusMap)
    },
    methods: {
      getMapToArr(map) {
        const arr = [...map.entries()].reduce((item, [key, value]) => {
          item.push({ key: key, value: value })
          return item
        }, [{ key: 0, value: '全部' }])
        return arr
      },
      // 获取订单列表函数
    async fetchData () {
        if (!this.filter.orderNo && !this.filter.userNo) {
            this.tableData = []
            return
        }
        this.tableLoading = true
        const params = {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          ...this.filter
        }
        if (params.goodsType === -1) delete params.goodsType
        if (params.state === 0) delete params.state
        if (params.payType === 0) delete params.payType
        this.params = params
        const { code, data } = await getShopOrderAPI(params) 
        this.tableLoading = false
        if (code !== 200) return
        this.tableData = data.data
        this.count = data.count
      },
      // 导出全部订单按钮
      async exportAllOrder() {
        if (this.count === 0) {
          this.$message.error('筛选的订单数为0, 请重新选择')
          return
        }
        this.spinning = true
        this.tip = '订单导出中'
        this.isDisAllOrder = true
        if (this.count > 5000) {
          this.$message.error('导出订单不能超过5000条')
          this.spinning = false
          this.isDisAllOrder = false
        } else {
          const { code, data } = await orderDownAPI(this.params)
          this.spinning = false
          this.isDisAllOrder = false
          if (code !== 200) return 
          if (data) {
            window.location.href = data
            this.$message.success('导出订单成功', 1)
          }
        }
      },
      // 下载模板的按钮
      async downloadTemplate() {
        this.isDisDownTemplate = true
        const { code, data} = await shopOrderDownloadAPI()
        this.isDisDownTemplate = false
        if (code !== 200) return
        this.importUrl = data
        window.location.href = data
      },
      // 订单导入中的
      spinningFn(bool) {
        this.tip = '订单导入中'
        this.spinning = bool;
      },
      // 导入订单返回的回调
      errOrderFn(data) {
        let that = this
        if (data.failCount > 0) {
          this.errImportVisible = true
          this.failCount = data.failCount
        } else {
          this.spinning = false;
          this.errImportVisible = false;
          Modal.success({
            title: "提示",
            content: '成功导入快递单号',
            okText: "确认",
            cancelText: '',
            width: "500px",
            onOk() {
              that.errImportVisible = false;
            },
          })
          that.fetchData()
        }
      },
      // 下单日期改变
      onChangeDate(date, dateString) {
        if (date.length === 0) {
          this.rangePicker = ['', '']
          this.filter.orderTimeStart = ''
          this.filter.orderTimeEnd = ''
          return
        }
        this.rangePicker = [
          moment(dateString[0], 'YYYY-MM-DD'),
          moment(dateString[1], 'YYYY-MM-DD'),
        ]
        this.filter.orderTimeStart = dateString[0] + ' 00:00:00'
        this.filter.orderTimeEnd = dateString[1] + ' 23:59:59'
      },
      // 回车和搜索的按钮
      searchClick() {
        this.pageNo = 1
        this.fetchData()
      },
      clearSearch() {
        this.filter = this.$options.data().filter
        this.rangePicker = []
      },
      // 退款的按钮
      refundClick(record) {
        let _that = this
        Modal.confirm({
          title: "提示",
          content: "退款后用户相关权限会取消且无法恢复, 是否确定申请退款",
          okText: "确认",
          okType: "danger",
          cancelText: "取消",
          width: "500px",
          onOk() {
            _that.shopOrderRefund(record.orderNo, ORDER_STATUS_APPLY_REFUND)
            _that.$message.success('申请退款成功', 2)
            record.state = ORDER_STATUS_APPLY_REFUND
          }
        })
      },
      // 确认退款
      confirmRefundFn(record) {
        let _that = this
        Modal.confirm({
          title: "提示",
          content: "退款后用户相关权限会取消且无法恢复, 是否确定确认退款",
          okText: "确认",
          okType: "danger",
          cancelText: "取消",
          width: "500px",
          onOk() {
            _that.shopOrderRefund(record.orderNo, ORDER_STATUS_REFUNDING)
            _that.$message.success('申请退款成功', 2)
            record.state = ORDER_STATUS_REFUNDING
          },
        })
      },
      async shopOrderRefund(orderNo, state) {
        const params = {
          orderNo: orderNo,
          state: state
        }
        const { code } = await shopOrderRefundAPI(params)
        if (code !== 200) return
      },
      // 驳回申请退款按钮
      rejectClick(record) {
        let _that = this
        Modal.confirm({
          title: "提示",
          content: "驳回退款后如有需要可重新申请,是否确认驳回",
          okText: "确认",
          okType: "danger",
          cancelText: "取消",
          width: "500px",
          onOk() {
            _that.shopOrderRefund(record.orderNo, ORDER_STATUS_REFUND_FAILED)
            _that.$message.success('驳回申请退款成功', 2)
            record.state = ORDER_STATUS_REFUND_FAILED
          },
        })
      },
      // 收货地址修改弹窗
      showReceiptAddressDlg(record) {
        this.currentRecord = Object.assign({}, record)
        this.addressModalVisible = true
      },
      // 保存收货地址
      async updateOrderReceiptAddress(record) {
        const params = {
          orderNo: record.orderNo,
          receiptName: record.receiptName,
          receiptPhone: record.receiptPhone,
          receiptArea: record.receiptArea,
          receiptAddress: record.receiptAddress
        }
        const { code } = await updateReceiptAPI(params)
        this.addressModalVisible = false
        if (code !== 200) return
        this.$message.success('修改成功')
        this.fetchData()
      },
      // 快递信息按钮
      expressClick(record) {
        this.currentRecord = Object.assign({}, record)
        this.modalVisible = true
      },
      // 快递信息模态框的确定按钮
      async updateExpress(obj) {
        const params = {
          orderNo: this.currentRecord.orderNo,
          trackingNumber: obj.trackingNumber,
          trackingName: obj.trackingName
        }
        const { code, data } = await updateExpressAPI(params)
        this.modalVisible = false;
        if (code !== 200) return
        this.$message.success('修改成功')
        this.fetchData()
      },
      // 分页功能切换的回调
      showSizeChangeFn(current, pageSize) {
        this.pageNo = current;
        this.pageSize = pageSize;
        this.fetchData()
      },
      
    }
  }
  </script>
  
  <style lang="less" scoped>
  #order_list {
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
  }
  
  .top_line {
    margin-top: 15px;
    margin-bottom: 20px;
    display: flex;
  
    span {
      margin-right: 20px;
  
      /deep/ .ant-input {
        width: 300px;
      }
  
      /deep/ .ant-select-selection {
        width: 110px;
        margin-left: 10px;
      }
  
      /deep/ .ant-calendar-picker-input {
        margin-left: 10px;
      }
    }
  
    .ant-btn {
      margin-right: 20px;
    }
  }
  
  .marign_left20 {
    margin-left: 20px;
  }
  
  /deep/ .ant-upload-picture-card-wrapper {
    width: auto;
  }
  
  /deep/ .ant-table-wrapper {
    margin-top: 20px;
  }
  
  /deep/ .ant-table-thead > tr > th {
    font-weight: 600;
  }
  
  /deep/ .ant-table-fixed-right {
    z-index: 99;
  }
  
  /deep/ .ant-table-fixed-header .ant-table-body-inner {
    background-color: #fff;
    z-index: 99;
  }
  </style>
  